:root {
  --primary-blue: #3A5F7D;
  --secondary-blue: #5DADE2;
  --primary-orange: #ff9500;
  --secondary-orange: #a95624;
  --neutral-bg: #F4F4F4;
  --dark-text: #333333;
  --highlight-color: #ff9500; /* New vibrant highlight color */
}

body, html {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

input, .CustomFields, .URLDisplay {
  margin-top: 10px; /* Adjust as needed */
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.App {
  position: relative;
  max-width: 88%;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
}

.content-container {
  display: flex;
  flex-direction: column;
  margin: auto; /* Center the container */
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.left-column, .right-column {
  width: 100%;
}

@media (min-width: 1120px) { /* Adjust this breakpoint to your needs */
  .content-container {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .left-column, .right-column {
    width: 49%; /* Adjust based on your preferred spacing */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-header {
  position: relative; /* Ensures absolute positioning is relative to this container */
  display: flex;
  flex-direction: column;
}

.gear-icon-button {
  position: absolute;
  top: 0px;
  right: 19px;
  margin-top: 24px;
  z-index: 980; /* higher than your modal's z-index */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  color: var(--primary-blue);
  font-size: 60px;
  text-align: center;
  font-family: "Baloo Bhaijaan 2", sans-serif;
  margin: auto;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 18px;
}

input[type="text"], input[type="url"], input[type="number"] {
  width: calc(100% - 12px);
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: var(--primary-blue);;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  font-size: 18px;
}

button:hover {
  background-color: var(--primary-orange);
}

.donation-button-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjusts spacing between buttons */
  margin-bottom: 20px; /* Add margin at the top for visual spacing from other elements */
  padding-bottom: 20px;
}

.donation-button {
  background-color: transparent; /* Light grey background */
  border-radius: 5px; /* Rounded corners */
  width: 56px; /* Set width */
  height: 56px; /* Set height to make it square */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none; /* Remove underline from links */
  color: black; /* Text color */
  font-size: 14px; /* Set font size */
}

.donation-button:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
}

/* Individual icon styles */
.venmo-icon {
  width: 42px; /* Example size for Venmo */
}

.paypal-icon {
  width: 40px; /* Example size for PayPal */
}

.bmc-icon {
  width: 32px; /* Example size for Buy Me A Coffee */
}

.kofi-icon {
  width: 54px; /* Example size for Ko-fi */
}

.button-container {
  display: flex;
  align-items: center; /* This will vertically center the buttons */
  justify-content: center; /* Adjust this as needed, depending on layout */
  gap: 10px; /* Adjust the gap between buttons */
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
}

.icon-btn, .copy-btn, .upload-btn {
  background: var(--secondary-blue);
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 32px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  justify-content: center;
  margin: 1px;
  transition: background-color 0.3s ease-in-out;
}

.icon-btn.success {
  background-color: #28a745; /* Green color */
  transition: background-color 0.3s;
}

.icon-btn .icon {
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.icon-btn .show-icon {
  opacity: 1;
}

.generate-btn {
  font-size: 22px; /* larger font size */
  padding: 10px 20px; /* larger button size */
  margin-top: 20px;
  display: block; /* necessary for centering */
  background-color: var(--primary-orange); /* exciting color */
  color: white; /* text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.generate-btn:hover, .copy-btn:hover {
  background-color: var(--secondary-orange); /* slightly darker on hover */
}

.generate-btn:disabled {
  background-color: #ccc; /* Gray color for disabled button */
  color: #666; /* Darker text color */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

/* Optional: Change styles on hover for disabled buttons (usually no change) */
.generate-btn:disabled:hover {
  background-color: #ccc;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--primary-blue);
  color: white;
  padding: 10px 24px; /* Reduced padding */
  margin: 5px;
  border-radius: 50px; /* Slightly rounded corners */
  font-size: 16px; /* Modern font size */
  font-weight: 400; /* Lighter font-weight */
  justify-content: center;
  transition: background-color 0.2s; /* Smooth transition for hover */
  animation: bubbleIn 0.2s ease forwards; /* Adjust duration as needed */
  cursor: pointer; 
  user-select: none;
}

.tag button {
  background-color: transparent;
  border: none;
  color: white;
  margin: 1px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px; /* Adjust size as needed */
}

.tag-removing {
  animation: vanish 0.4s ease forwards; /* Adjust duration as needed */
}

.tag-close-icon {
  margin-left: 8px;
  font-size: 10px;
}

.tag:hover {
  background-color: var(--secondary-blue); /* Slight color change on hover */
}

.tag:hover button {
  color: var(--neutral-bg);
}

.field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
}

.add-field-btn {
  width: 100%; 
}

.add-field-btn.disabled {
  background-color: #ccc; /* Gray background */
  color: #666;           /* Darker text color */
  cursor: not-allowed;   /* Change cursor */
  pointer-events: none;  /* Disable hover and click effects */
}

.field-container:hover {
  background-color: #e8e8e8;
}

.remove-field-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 2px solid var(--primary-orange);
  border-radius: 50%;
  color: var(--primary-orange);
  background: transparent;
  cursor: pointer;
  font-size: 30px;
  padding: 0;
  line-height: 1;
  margin-left: 10px;
}

.remove-field-btn:hover {
  background-color: var(--secondary-orange);
  color: white;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

/* Container for the ComboBox, including the input and label */
.ComboBox {
  position: relative;
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  gap: 5px; /* Provides space between children */
  background-color: #fff; /* Background color for the container */
  margin-top: 16px; /* Space between ComboBoxes */
}

/* Style for the input field inside the ComboBox */
.ComboBox-input {
  flex-grow: 1;
  padding: 8px 10px;
  border: none; /* Removes the border from the input */
  outline: none; /* Removes the outline on focus */
  font-size: 16px; /* Font size should match the label */
}

/* Style for the ComboBox label */
.ComboBox-label {
  position: absolute;
  left: 10px;
  top: 20px; /* Initially positioned within the container */
  transition: all 0.3s ease;
  color: #71797E; /* Initial color */
  pointer-events: none; /* Clicks go through the label */
  background: #fff; /* Match the container background */
  padding: 0 4px; /* Padding to prevent text touching the border */
}

/* Style for the ComboBox label when the input is focused or has content */
.ComboBox-input:focus + .ComboBox-label,
.ComboBox-input:not(:placeholder-shown) + .ComboBox-label {
  position: absolute;
  left: 6px;
  top: -5px; /* Initially positioned within the container */
  transition: all 0.3s ease;
  color: #71797E; /* Initial color */
  pointer-events: none; /* Clicks go through the label */
  background: #fff; /* Match the container background */
  padding: 0 4px; /* Padding to prevent text touching the border */
}

/* Styles for the input field when it is focused */
.ComboBox-input:focus {
  border-color: var(--highlight-color); /* Orange border color */
  box-shadow: 0 0 0 2px var(--highlight-color); /* Orange shadow for emphasis */
}


/* Styles for the '+' button */
.ComboBox button {
  width: 40px; /* Width equals height to form a square */
  height: 40px; /* Adjust size to match design */
  display: flex; /* Flex display to center the '+' sign */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Adjust the '+' button position */
.ComboBox button {
  margin: 0;
  align-self: center;
}

/* Additional styles to make disabled state more obvious */
.ComboBox button.disabled {
  background-color: #e0e0e0; /* Light gray background */
  color: #9e9e9e; /* Dim the text color */
  border: 1px solid #ccc; /* Light gray border to match */
  cursor: not-allowed; /* Cursor to indicate non-interactivity */
}

/* Ensure the styles are specific enough to override any other button styles */
.ComboBox button.disabled:hover {
  background-color: #e0e0e0; /* Keep the same color even on hover */
}

.ComboBox-helper-text {
  font-size: 14px;
  font-style: italic;
  color: #6c757d; /* A neutral color for helper text */
  margin-left: 16px;
  margin-right: 32px;
}

.ComboBox-input.disabled,
.ComboBox-button.disabled {
  background-color: #f3f3f3; /* Light gray background */
  color: #ccc; /* Gray out the text */
  border-color: #ddd; /* Lighter border */
  cursor: not-allowed; /* Show a not-allowed cursor */
}

.ComboBox-label.disabled {
  color: #ccc; /* Gray out the label */
  background-color: #f3f3f3; /* Light gray background */
}

.tag.disabled {
  background-color: #eee; /* Disabled tag background */
  color: #bbb; /* Disabled text color */
  pointer-events: none; /* No interaction */
}

.ComboBox-helper-text.disabled {
  color: #aaa; /* Dim the helper text */
}

.Generate-helper-text {
  font-size: 14px;
  font-style: italic;
  text-align: center; /* Center text horizontally */
  color: #DC143C; /* A neutral color for helper text */
  margin: 16px; /* Add some space around the helper text */
}

.icon-btn-helper-text {
  font-size: 14px;
  font-style: italic;
  text-align: center; /* Center text horizontally */
  transition: opacity 0.5s ease-in-out;
  opacity: 0; /* Start as invisible */
  color: #555; /* Adjust color as needed */
  font-size: 14px; /* Adjust size as needed */
  margin-top: 8px; /* Space from the preceding element */
  margin-bottom: 8px;
  visibility: hidden;
}

.icon-btn-helper-text.visible {
  opacity: 1; /* Fully visible when class 'visible' is added */
  visibility: visible;
}

.url-display-group {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: center;
    margin-bottom: 16px;
}

.url-display-header {
  display: inline-flex;
  align-items: center; /* This ensures vertical centering */
  gap: 10px; /* Adjust the gap between text and buttons if necessary */
  font-size: 18px;
  color: #71797E;
  font-weight: bold;
}

.url-container {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  max-width: 100%;
  width: auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: max-height 0.5s ease-out, transform 0.5s ease;
}

/* Styles for the scrollbar */
.url-container::-webkit-scrollbar, .url-container::-webkit-scrollbar-track:horizontal {
  width: 8px; /* Adjust the width of the scrollbar */
  height: 8px;
}

.url-container::-webkit-scrollbar-track {
  background: transparent;
}

.url-container::-webkit-scrollbar-thumb {
  background: darkgray; /* Color of the scrollbar thumb */
  border-radius: 15px;
}

.url-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.url-container-expand {
  max-height: 500px; /* Set to a value higher than what you expect the content's height to be */
  animation: ease-in-out forwards; /* Animation for drawing the borders */
}

/* Add a transition delay for the border drawing */
.url-container-expand::before {
  transition-delay: 0s; /* Start immediately */
}

.url-container-expand::after {
  transition-delay: 0.5s; /* Start after top and bottom are done */
}

.url-container::before, .url-container::after {
  position: absolute;
  transition: transform 0.5s ease;
}

/* Top and bottom borders */
.url-container::before,
/* Left and right borders */
.url-container::after {
  position: absolute;
  z-index: 1; /* Ensure it's above the content */
}

.url-container::before {
  top: 0;
  left: 0;
  right: 0;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 0.2s ease; /* No delay for the first animation */
}

.url-container::after {
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.5s ease; /* No delay for the first animation */
}

.url-container-expand {
  max-height: 500px; /* Adjust to allow content to show */
}

.url-container-expand::before {
  transform: scaleX(1);
  transition-delay: 0.1s; /* Delay to sync with max-height */
}

.url-container-expand::after {
  transform: scaleY(1);
  transition-delay: 0.1s; /* Start after top border is done */
}

.url-content {
  opacity: 0;
  padding: 12px; /* Added padding */
}

.url-container-expand .url-content {
  opacity: 1;
}

/* Initial state for collapsed container, no height, no border */
.url-container-collapse {
  max-height: 0;
  border: 1px solid #ccc; /* Keep the border color */
  transition: max-height 0.2s ease-in, border 0.5s ease-in 0.5s; /* Delay the border collapse */
}

.url-container-collapse .url-content {
  opacity: 0;
  transition: opacity 0.1s ease-in; /* Fade out immediately */
}

.url-container-collapse::before {
  transform: scaleX(1); /* Initially full width */
  transition: transform 0.5s; /* Delay to start after content fades out */
}

.url-container-collapse::after {
  transform: scaleY(1); /* Initially full height */
  transition: transform 0.5s; /* Delay to start after content fades out */
}

.url-container.set-scroll {
  overflow-y: scroll;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes collapseBorder {
  0% {
    border-color: #ccc;
  }
  100% {
    border-color: transparent;
  }
}

.url-container-collapse {
  animation: collapseBorder 0.1s ease-in-out forwards;
}

.upload-btn {
  /* Common properties for the button */
  background-repeat: no-repeat;
  background-size: 100% 0;
  transition: background-size 2s, background-color 0.3s;
}

.upload-btn .icon {
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  opacity: 0; /* Start with icons hidden */
}

.upload-btn.idle .cloud-icon {
  opacity: 1;
}

.upload-btn.complete .cloud-icon {
  opacity: 1; /* Hide the cloud icon in complete state */
}

.upload-btn.complete .check-icon {
  opacity: 0; /* Hide the check icon in complete state */
}

.upload-btn.success .check-icon {
  opacity: 1; /* Show the check icon in success state */
}

.upload-btn.success .cloud-icon {
  opacity: 0; /* Show the check icon in success state */
}

/* Ensure the error icon is visible in 'error' state */
.upload-btn.error .error-icon {
  opacity: 1;
}

.upload-btn .show-icon {
  opacity: 0;
}

.upload-btn.complete {
  background-color: #7dd07d; /* Pale green color */
  pointer-events: none;
  cursor: not-allowed;
}

.upload-btn.idle {
  /* Neutral state */
  background-color: var(--secondary-blue);
  /* Ensure the gradient is reset */
  background-image: none;
}

.upload-btn.uploading {
  /* Uploading state */
  background-image: linear-gradient(to top, #28a745 0%, #28a745 100%);
  background-size: 100% 100%;
}

.upload-btn.success {
  /* Success state */
  background-color: #45a049; /* Green color */
  /* Reset background size to prevent gradient effect */
  background-size: 100% 0;
}

.upload-btn.success .icon-success {
  opacity: 1;
}

/* Error and disabled states */
.upload-btn.error {
  background-color: #dc3545; /* Red color */
}

.upload-btn.error .icon-error {
  opacity: 1;
}

.upload-btn.disabled {
  background-color: #7dd07d; /* Pale green color */
  pointer-events: none;
  cursor: not-allowed;
}

.upload-btn:hover {
  background-color: #45a049;
}

@keyframes label-slide-up {
  from {
    top: 50%;
    font-size: 16px;
    color: #aaa;
  }
  to {
    top: -10px;
    font-size: 14px;
    color: var(--primary-orange);
  }
}

@keyframes bubbleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Keyframes for the 'vanish' animation */
@keyframes vanish {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 64px; /* Adjust size here */
  height: 32px; /* Adjust size here */
  margin-top: 8px;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  position: absolute;
  cursor: pointer;
  height: 32px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Off state color */
  transition: 0.4s;
  border-radius: 32px;
}

.toggle-switch-label:before {
  position: absolute;
  content: "";
  top: 2px;
  left: 2px;
  height: 28px; /* Adjust size here */
  width: 28px; /* Adjust size here */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch-checkbox:checked + .toggle-switch-label {
  background-color: var(--primary-blue); /* On state color */
}

.toggle-switch-checkbox:checked + .toggle-switch-label:before {
  transform: translateX(32px); /* Adjust slide distance */
}

.toggle-switch-inner {
  display: none;
}

.toggle-switch-switch {
  display: none;
}

.encode-toggle-container {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
  font-style: italic;
  color: #6c757d; /* A neutral color for helper text */
}

.question-mark-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}

.question-mark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
  font-style: normal;
  background-color: #d3d3d3;
}

.speech-bubble {
  position: absolute;
  min-width: 270px; /* Adjust based on the smallest size you want */
  white-space: normal; /* Ensure text wraps */
  top: 100%; /* Adjust position to display below the question mark */
  left: 0%; /* Align the bubble to the start (left edge) of the question mark */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease; /* Smooth transition for opacity */
  font-style: normal;
  z-index: 999;
}

.question-mark-container:hover .speech-bubble {
  visibility: visible;
  opacity: 1;
}

.speech-bubble a {
  color: var(--primary-blue); /* Hyperlink color */
  text-decoration: underline; /* Ensures it's clearly a link */
  font-style: italic;
}

.settings-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.settings-content {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 44%; /* Full width of the viewport */
  max-widht: none;
  max-height: 100vh; /* Full height of the viewport */
  overflow-y: auto; /* Enables scrolling */
}

.close-button {
  float: right;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.checkbox-control, .input-control, .add-control {
  margin-bottom: 1.5em;
}

.add-control input, .add-control button {
  margin-top: 0.5em;
}

section {
  margin-top: 20px;
}

.manage-section {
  background-color: #f7f7f7;
  padding: 24px;
  margin-top: 10px;
  border-radius: 5px;
  max-height: 300px; /* Adjust to your preference */
  overflow-y: auto; /* Enables scrolling */
}

.manage-section input[type="text"] {
  width: calc(100% - 90px); /* Adjust width to account for the button */
  margin-right: 5px;
}

.manage-section button {
  /* Button styles */
}

.list-item {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space between input and buttons */
  padding: 10px 0; /* Add some padding for spacing */
  border-bottom: 1px solid #eee; /* Distinguishes rows */
  margin: 5px;
}

.list-item:last-child {
  border-bottom: none;
}

.modal-btn, .list-item input[type="text"] {
  padding: 8px 12px; /* Adjust padding as needed */
  margin: 0 5px; /* Space between buttons */
  border: 1px solid #ccc; /* Border for distinction */
  border-radius: 4px; /* Rounded corners */
}

.list-item input[type="text"] {
  flex-grow: 1; /* Allow input to grow and fill space */
  margin-right: 5px; /* Maintain margin to the right */
}

.list-item:nth-child(odd) {
  background-color: #f9f9f9;
}

.list-item:nth-child(even) {
  background-color: #e9e9e9;
}

.list-item:not(.editing) .save-btn,
.list-item:not(.editing) .cancel-btn,
.list-item.editing .edit-btn,
.list-item.editing .delete-btn {
  display: none;
}

.add-control, .list-item {
  display: flex;
  align-items: center;
}

.modal-btn {
  height: 36px;
  margin-right: 5px; /* Remove margin or adjust as necessary */
}


h2 {
  font-size: 1.5em;
  font-weight: bold;
  padding-top: 0.4em;
  padding-left: 0.2em;
}

h3 {
  font-size: 1.2em;
  color: #333;
  margin-top: 2em;
  color: var(--primary-blue);
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
  max-height: 150px; /* fixed height with scrolling */
  overflow-y: auto;
}

li {
  padding: 8px;
  transition: background-color 0.2s ease; /* smooth transition for hover effect */
}

li:hover {
  background-color: #f7f7f7; /* subtle hover state */
}

.donation-container {
  border: 4px solid red;
}

.advanced-options-toggle h2 {
  cursor: pointer;
  color: var(--primary-blue);
  user-select: none;
}

.advanced-options-toggle h2:hover {
  color: var(--primary-orange);
}

.chevron-icon {
    margin-left: 10px; /* Adjust the value as needed */
}